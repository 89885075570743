import { useWindowWidthResize } from 'hooks/useWindowWidth';
import {
  ChartOptions,
  createChart,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  LineData,
  PriceScaleMode,
  SeriesType,
} from 'lightweight-charts';

import { FC, useEffect, useState } from 'react';

const chartOptions = ({ isPercentage }: { isPercentage?: boolean }) => ({
  layout: {
    backgroundColor: '#000000',
    textColor: '#d1d4dc',
  },
  rightPriceScale: {
    scaleMargins: {
      top: 0.3,
      bottom: 0.25,
    },
    borderVisible: false,
    ...(isPercentage ? { mode: PriceScaleMode.Percentage } : {}),
  },
  grid: {
    vertLines: { color: 'rgba(42, 46, 57, 0)' },
    horzLines: { color: 'rgba(42, 46, 57, 0.6)' },
  },
  width: 800,
  height: 300,
  timeScale: {
    timeVisible: true,
    secondsVisible: false,
  },
  axisDoubleClickReset: true,
  resetTimeScale: true,
});

export const LineChart: FC<{ series: LineData[]; isPercentage?: boolean }> = ({ series, isPercentage }) => {
  const [chart, setChart] = useState<ChartModel>();
  const [containerElement, setContainerElement]
      = useState<HTMLDivElement | null>(null);

  const initChart = () => {
    if (containerElement) {
      chart?.destroy();
      setChart(
        new ChartModel(containerElement, chartOptions({ isPercentage })),
      );
    }
  };

  useEffect(() => {
    initChart();

    return () => {
      chart?.destroy();
    };
  }, [containerElement]);

  useEffect(() => {
    chart?.update(series);
  }, [chart, series.length]);

  useWindowWidthResize(() => {
    if (!chart) {
      return;
    }

    const width = chart.element.parentElement?.clientWidth ?? 0;
    const height = chart.element.clientHeight;

    if (width && height) {
      chart?.resize(width, height);
    }
  }, [chart]);

  return (
    <div
      className="trades-chart"
      ref={ref => setContainerElement(ref)}
    />
  );
};

class ChartModel {
  private chart: IChartApi;
  private areaSeries: ISeriesApi<SeriesType>;
  element: HTMLDivElement;
  isDestroyed = false;

  constructor(element: HTMLDivElement, options: DeepPartial<ChartOptions>) {
    this.element = element;
    this.chart = createChart(element, options);
    this.areaSeries = this.chart.addAreaSeries({
      topColor: 'rgba(38,198,218, 0.56)',
      bottomColor: 'rgba(38,198,218, 0.04)',
      lineColor: 'rgba(38,198,218, 1)',
      lineWidth: 2,
    });
  }

  update(series: LineData[]) {
    this.areaSeries.setData(series);
    this.chart.timeScale().fitContent();
  }

  resize(width: number, height: number) {
    this.chart.resize(width, height);
    this.chart.timeScale().fitContent();
  }

  destroy() {
    this.isDestroyed = true;
    this.chart.remove();
  }
}
